import React, { useEffect } from 'react';
import Accessibility from '../components/Terms/Accessibility';
import NavBar from '../components/Navbar';
import Footer from '../components/Footer';
import { mixpanelTrack } from '../utils/datalayer/dataLayer';

function IndexPage() {
  useEffect(() => mixpanelTrack('efficiency'));
  return (
    <>
      <NavBar />
      <Accessibility />
      <Footer />
    </>
  );
}

export default IndexPage;

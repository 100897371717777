import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Section, Wrapper, Headline, Text } from './styled';

export default function TermsPrivacy() {
  return (
    <Section>
      <Wrapper>
        <Headline>
          <FormattedMessage id="accessibility.title" />
        </Headline>
        <Text>
          <FormattedMessage id="accessibility.item1" />
        </Text>
        <Text>
          <FormattedMessage id="accessibility.item2" />
        </Text>
        <Text>
          <FormattedMessage id="accessibility.item3" />
        </Text>
        <Text>
          <FormattedMessage
            id="accessibility.item4"
            values={{
              // eslint-disable-next-line react/no-unstable-nested-components
              ada: (chunks) => <a href="mailto:ada@evapolar.com">{chunks}</a>,
            }}
          />
        </Text>
        <Text>
          <FormattedMessage id="accessibility.item5" />
        </Text>
      </Wrapper>
    </Section>
  );
}
